@import "../../assets/util";

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: map-get($spacers, 5);
}

.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // space-evenly is not supported in IE, so use space-around
  @supports (justify-content: space-evenly) {
    justify-content: space-evenly;
  }
  justify-content: space-around;

  align-items: center;
  text-align: center;
  padding: map-get($spacers, 4) 0px;
}


.textBody {
  font-size: map-get($font-sizes, 1);
  padding-left: map-get($spacers, 7);
  padding-right: map-get($spacers, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  width: 100%;

  p {
    margin: map-get($spacers, 1) 0px;
  }
}

.exit {
  margin: map-get($spacers, 3) map-get($spacers, 3) 0 0;
  height: $exit-icon-size;
  width: calc(#{$exit-icon-size} * 2) // otherwise this gets cut off in IE
}

.logo {
  margin-bottom: map-get($spacers, 1);
}

.privacyPolicy {
  font-size: map-get($font-sizes, 1);
}



@include media-breakpoint-down(sm1) {
  .container {
    padding-left: map-get($spacers, 2);
  }

  .leftPanel {
    padding: map-get($spacers, 2) 0px;
    padding-left: map-get($spacers, 2);
  }

  .privacyPolicy {
    font-size: map-get($font-sizes, 0);
  }

  .textBody {
    line-height: 1.5;
    font-size: 0.75rem;
    padding-left: map-get($spacers, 5);
  }
}

// 315px is right when the text overflows
@media (max-width: 315px) {
  .textBody {
    justify-content: flex-start;
    overflow: scroll;
  }
}

// Edge "supports" space-evenly but doesn't actually implement it
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15947692/
// this detects edge: https://stackoverflow.com/questions/32201586/how-to-identify-microsoft-edge-browser-via-css
_:-ms-lang(x), _:-webkit-full-screen, .leftPanel {
  justify-content: space-around;
}
