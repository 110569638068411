@import '../../assets/util';

$max-width: 570px;
$height: 210px;

.highlightCard {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: $gray-300;
  background-color: $white;

  max-width: $max-width;
  min-height: $height;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.audioControls {
  margin-bottom: map-get($spacers, 2);
}

.footer {
  font-size: map-get($font-sizes, 1);
  display: flex;
  justify-content: space-between;
  color: $gray-600;
  padding: map-get($spacers, 1) map-get($spacers, 5);
  background-color: $gray-100;

  .shareText {
    margin-left: map-get($spacers, 1);
  }

  .clickable {
    color: $fora-purple;
    cursor: pointer;
    font-size: map-get($font-sizes, 1);
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .hostInfo {
    margin: 0px 5px;
    max-width: 290px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .footerSeparator {
    padding: 0px 5px;
  }
}

.overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 2;
}

.animated {
  animation-duration: 500ms;
  animation-timing-function: $ease-cubic-in-out;
  animation-fill-mode: both;
}

// for accessibility
@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

.transcript {
  padding: 0 map-get($spacers, 5);
}
