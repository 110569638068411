@import '../../assets/util';

.container {
  position: relative;
  margin: map-get($spacers, 6) 0;
  max-height: 75px; // otherwise this makes the footer too small in Safari
  &.toEdges {
    margin-left: -1rem;
    margin-right: -1rem;

    .words {
      padding: 0 1rem;
    }
  }
  overflow: hidden;
}

.words {
  font-size: map-get($font-sizes, 3);
  min-height: 2em; // roughly 3lines to stop it jumping when one line happens
  line-height: 1.95;
  display: flex;
  align-items: center;
  justify-content: center;

  // for animation purposes
  position: relative;
  overflow: hidden;
  transition: 0.5s transform ease-in-out;
  will-change: transform;
}

// for animation, needs absolute position
.wordsInner {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  will-change: transform;
}

.word {
  position: relative;

  &:before {
    content: '';
    background-color: transparent;
    box-shadow: none;
    transition: 100ms background-color;
    will-change: background-color;
    z-index: -1;
    position: absolute;
    top: -0.12em; // negative to make bigger around the word
    bottom: -0.12em;
    left: -0.24em;
    right: -0.24em;
  }
}

.clickableWord {
  cursor: pointer;
  &:hover {
    color: $gray-700;
  }
}

.seekHighlightBar {
  background-color: rgba($fora-purple-light, 0.2);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1.7em;
  width: 2em;
  margin-top: -0.125em;
  padding: 0 0.2em;
  margin-left: -0.2em;
  transition: 0.1s transform ease-in-out, 0.1s width ease-in-out;
  will-change: transform, width;
}

.snippet {
  transition: 0.3s color;
  will-change: color;
}

.inactiveSnippet {
  color: $gray-500;
  transition: 0.3s color;
  will-change: color;
}

.seekedWord {
  // color: $fora-purple;
  // &:before {
  //   // animation-name: wordpulse;
  //   // animation-duration: 0.3s;
  //   // animation-timing-function: ease-in-out;
  //   box-shadow: 0 4px 4px $gray-200;
  //   background-color: $fora-purple-light04;
  //   transition: 300ms background-color, 300ms box-shadow;
  // }
}

/* optional animation for pulsing the word box
@keyframes wordpulse {
  $pulse-size: 1.2;
  from {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    -webkit-transform: scale3d($pulse-size, $pulse-size, $pulse-size);
    transform: scale3d($pulse-size, $pulse-size, $pulse-size);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
*/

@include media-breakpoint-down(sm2) {
  .container {
    margin: map-get($spacers, 3) 0;
    max-height: 100px; // otherwise this makes the footer too small in mobile
  }

  .words {
    font-size: map-get($font-sizes, 2);
  }
}
