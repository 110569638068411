@import '../../assets/util';

.container {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: map-get($spacers, 3) map-get($spacers, 4);
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.exit {
  position: absolute;
  top: map-get($spacers, 3);
  right: map-get($spacers, 3);
}

.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textBody {
  font-size: map-get($font-sizes, 3);
  margin-left: map-get($spacers, 6);
  margin-top: -6px; // adjust for font's spacing
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.6;
  width: 100%;
}

.buttonGroup {
  margin-top: map-get($spacers, 5);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.button {
  box-sizing: content-box;
  margin-right: map-get($spacers, 4);
  &:last-child {
    margin-right: 0;
  }
}

.playAgain {
  color: $fora-purple;
  background: transparent;
  border: 1px solid $fora-purple;

  &:hover {
    color: $white;
    background: $fora-purple;
  }

  &:active {
    color: $white;
    background: darken($fora-purple, 8%);
  }
}

.replayIcon {
  margin-right: map-get($spacers, 1);
}

@include media-breakpoint-down(sm2) {
  .textBody {
    font-size: map-get($font-sizes, 1);
    margin-left: 0;
    margin-top: 0;
    padding: 0 map-get($spacers, 3);
  }

  .contentContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    height: 100%;
  }

  .buttonGroup {
    justify-content: center;
  }

  .button {
    width: auto;
  }
}

@media (max-width: 295px) {
  .buttonGroup {
    margin-top: map-get($spacers, 2);
    justify-content: space-evenly;
  }

  .button {
    margin-right: 0;
    margin-bottom: map-get($spacers, 1);
  }
}
