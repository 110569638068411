
.front {
  stroke: white;
}

.back {
  stroke: rgba(255, 255, 255, 0.3);
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.isSpinning {
  animation: spin 1s linear infinite;
}
