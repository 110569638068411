@import '../../assets/util';

.AudioControls {
  $bar-height: 5px;
  $bar-height-hover: 9px;
  background: transparent;

  font-size: 13px;
  color: $gray-700;
  display: flex;
  white-space: nowrap;
  align-items: center;

  .play-status-bar {
    flex: 1;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .duration-bar,
  .seek-bar {
    top: 0;
    width: 100%;
    height: $bar-height;
    position: absolute;
    z-index: 1;
  }

  .seek-preview-bar {
    height: $bar-height;
    background: $fora-purple-light;
    position: absolute;
    z-index: 2;
  }

  .interaction-bar {
    flex: 1;
    padding-bottom: 12px;
    position: relative;
  }

  .duration-bar {
    background-color: $fora-purple-light04;
    transition: 0.3s background-color, 0.1s height;
  }

  .seek-bar,
  .seek-preview-bar {
    transition: 0.3s background-color, 0.1s height;
  }

  &.loading .duration-bar {
    background-color: $gray-200;
  }

  &.notLoaded .duration-bar {
    background-color: $white;
  }

  .seek-bar {
    background-color: $fora-purple-light80;
  }

  &:hover {
    .duration-bar,
    .seek-bar,
    .seek-preview-bar {
      height: $bar-height-hover;
    }
    .seek-bar {
      background-color: $fora-purple;
    }
  }
}

.tooltip {
  text-align: center;
  background: $gray-200;
  color: $gray-800;
  font-size: 12px;
  border-radius: 100px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  padding: 2px 6px;
  font-variant-numeric: lining-nums tabular-nums;
  pointer-events: none;
}
