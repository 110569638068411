@import '../../assets/util';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 map-get($spacers, 5);
}

.playButton {
  color: $fora-purple;
  cursor: pointer;
}

.leftHeader {
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
}

.headerText {
  margin-left: map-get($spacers, 3);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.speaker {
  font-weight: bold;
  font-size: map-get($font-sizes, 2);
  margin-bottom: 1px;
}

.location {
  font-size: map-get($font-sizes, 1);
  color: $gray-700;
}

@include media-breakpoint-down(sm1) {
  .speaker {
    margin-bottom: 0;
    line-height: 1.25;
  }
}
