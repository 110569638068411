@import '../../assets/util';

.button {
  $button-size: 40px;
  border-radius: $button-size;
  width: $button-size;
  height: $button-size;
  background: $fora-purple;
  color: white;
  appearance: none;
  border: none;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: 0.2s background;

  &:hover {
    background: darken($fora-purple, 4%);
  }

  &:active {
    background: darken($fora-purple, 8%);
  }

  &:focus {
    outline: none;
  }
}

.play {
  transform: translate(2px, 0);
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
}

.error {
  background: $red;
  &:hover {
    background: darken($red, 4%);
  }

  &:active {
    background: darken($red, 8%);
  }
}
