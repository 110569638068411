@import '../../assets/util';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;

  // space-evenly is not supported in IE, so use space-around
  @supports (justify-content: space-evenly) {
    justify-content: space-evenly;
  }
  justify-content: space-around;

  align-items: center;
  min-height: 210px;
  height: 100%;
  text-align: center;
}

.footer {
  padding: map-get($spacers, 1) map-get($spacers, 5);
  background-color: $gray-100;
  display: flex;
  justify-content: center;

  .clickable {
    color: $fora-purple;
    cursor: pointer;
    font-size: map-get($font-sizes, 1);
  }

  .clickable:hover {
    text-decoration: underline;
  }
}

// Edge "supports" space-evenly but doesn't actually implement it
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15947692/
// this detects edge: https://stackoverflow.com/questions/32201586/how-to-identify-microsoft-edge-browser-via-css
_:-ms-lang(x),
_:-webkit-full-screen,
.content {
  justify-content: space-around;
}
