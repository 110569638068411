@import "../../assets/util";

.button {
  cursor: pointer;
}

.button:hover {
  svg {
    color: $gray-800;
  }
}
