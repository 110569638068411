@import 'assets/util';

body {
  margin: 0;
  //padding: 10px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
textarea,
input {
  font-family: 'Open Sans', sans-serif;
}

a {
  color: $fora-purple;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
