@import '../../assets/util';

.button {
  font-size: map-get($font-sizes, 1);
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s background;
  padding: map-get($spacers, 2) map-get($spacers, 3);
  text-align: center;
  border: none;
  background: $fora-purple;
  color: $white;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: darken($fora-purple, 4%);
    text-decoration: none;
  }

  &:active {
    background: darken($fora-purple, 8%);
  }

  &:focus {
    outline: none;
  }
}

@include media-breakpoint-down(sm1) {
  .button {
    font-size: map-get($font-sizes, 0);
    padding: 0 map-get($spacers, 2);
    line-height: 2.4;

    &.buttonMultiline {
      line-height: 1.5;
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 1);
    }
  }
}
