@import "../../assets/util";

.copy {
  width: 100%;
  display: flex;
}

.copyArea {
  resize: none;
  border: 1px solid $gray-400;
  border-radius: 2px;
  padding: map-get($spacers, 2) map-get($spacers, 3);
  font-size: map-get($font-sizes, 1);
  max-height: 40px;
  width: 100%;
  color: $gray-800;
  box-sizing: border-box;
}
