@import "../../assets/util";

.container {
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  height: 100%;
  padding: map-get($spacers, 4) 0 map-get($spacers, 4) map-get($spacers, 6);
  box-sizing: border-box;

  p {
    margin: 0 0 map-get($spacers, 1);
  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .bottomText {
      color: $gray-600;
    }
  }
}

.contentInner {
  width: 100%;
}

.formGroup {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.subtitle {
  font-size: map-get($font-sizes, 1);
  margin-bottom: 0;
}

.header {
  font-size: map-get($font-sizes, 1);
  font-weight: bold;
  // line-height: 1;
  margin: 0;
  margin-bottom: map-get($spacers, 1);
}

.exit {
  float: right;
  margin: map-get($spacers, 3) map-get($spacers, 3) 0 0;
}


@include media-breakpoint-down(sm1) {
  .subtitle {
    font-size: map-get($font-sizes, 1);
  }
}
